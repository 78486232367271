import { Inject, Injectable } from '@angular/core';
import { BROWSER_STORAGE } from '../_helpers/storage';
import { JwtHelperService } from "@auth0/angular-jwt";

@Injectable({
  providedIn: 'root'
})
export class SessionManagerService {

  constructor(@Inject(BROWSER_STORAGE) private storage: Storage) { }

  public getToken(): string {
    return this.storage.getItem('token');
  }

  public setToken(token: string): void {
    this.storage.setItem('token', token);
  }

  public get isloggedIn(): boolean{
    let token = this.getToken();
    if(token !== null){
      const helper = new JwtHelperService();

      const decodedToken = helper.decodeToken(token);
      console.log("Decoded Token : " + decodedToken);
      const expirationDate = helper.getTokenExpirationDate(token);
      const isExpired = helper.isTokenExpired(token);
      if(!isExpired){
        return true;
      }
      else{
        return false;
      }
    }
    return false;
  }
}
