import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
}

export const ROUTES: RouteInfo[] = [
  { path: '/dashboard', title: 'Dashboard',  icon: 'ni-tv-2 text-primary', class: '' },
  { path: '/userlist', title: 'User List',  icon:'ni-ui-04 text-primary', class: '' },
  { path: '/segregation', title: 'Trash Management',  icon:'ni-bullet-list-67 text-red', class: '' },
  { path: '/upload-images', title: 'Trash Analysis Image Upload',  icon:'ni-image text-primary', class: '' },
  { path: '/image-segregation', title: 'Trash Analysis Brand Segregation',  icon:'ni-ungroup text-primary', class: '' },
  { path: '/waste-classification', title: 'Trash Analysis Waste Classification',  icon:'ni-ungroup text-primary', class: '' }
  
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  public menuItems: any[];
  public isCollapsed = true;

  constructor(private router: Router) { }

  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem);
    this.router.events.subscribe((event) => {
      this.isCollapsed = true;
   });
  }
}
